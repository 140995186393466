import {
  BrowserRouter,
  Route,
  Switch
} from "react-router-dom";
import Index from "./Components/Index"

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route exact path='/' component={() => <div className="video_ended">404! page not found</div>} />
          <Route exact path="/connect/:session_id/:host">
            <Index />
          </Route>
          <Route exact path="/connect/:session_id/:host/:name">
            <Index />
          </Route>
          <Route component={() => <div className="video_ended">404! page not found</div>} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}
export default App;
