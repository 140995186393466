import React, { useEffect } from 'react'

function Index() {

    
    return (
        <div>
            <div id="video">


            </div >
        </div>
    )
}

export default Index
