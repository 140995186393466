import React from 'react'
import "./Index.js"
function Loading() {
    return (
        <div className="loader_div">
            <div className="big-circle">
                {/* <div className="small-circle"></div> */}
            </div>
        </div>
    )
}

export default Loading
