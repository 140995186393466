import axios, { CancelToken } from "axios";
import Swal from 'sweetalert2'

export function setChatStoreData(name, value) {
    return {
        type: 'SET_CHAT_DATA',
        payload: { [name]: value },
    }
}

export const chatDetails = (token, group_id, page, cb, Authorization_cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'CHAT_ALL_REQUEST', payload: { chat_details_loader: true } })
    axios.get(`${process.env.REACT_APP_BASE_URL}chatDetails?group_id=${group_id}&page=${page}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                let data = res.data.chat_details ? res.data.chat_details.data : []
                let channel = res.data.channel_name
                dispatch({
                    type: 'CHAT_ALL_SUCCESS', payload: {
                        chat_data: data,
                        chat_next_page: res.data.chat_details ? res.data.chat_details.next_page_url : '',
                        chat_name: res.data.name,
                        chat_details_loader: false,
                        current_user_id: res.data.user_id,
                        channel_id: res.data.channel_id,
                    }
                })
                cb(data, channel, res.data)
            }

        })
        .catch(err => {
            dispatch({ type: 'CHAT_ALL_FAILED', payload: { chat_details_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Authorization_cb()
                    }
                }
            }
        }
        )

}

export const createChat = (token, message, id, type, Authorization_cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    let data = {}
    if (type === 'group') {
        data.group_id = id
        data.message = message
    }

    dispatch({ type: 'CHAT_ALL_REQUEST', payload: {} })
    axios.post(`${process.env.REACT_APP_BASE_URL}createChat`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'CHAT_ALL_SUCCESS', payload: {}
                })
            }
            else {
                dispatch({ type: 'CHAT_ALL_FAILED', payload: {} })
                // Swal.fire({
                //     icon: 'error',
                //     title: '',
                //     text: res.data.message,

                // })
            }
        })
        .catch(err => {
            dispatch({ type: 'CHAT_ALL_FAILED', payload: {} })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Authorization_cb()
                    }
                }

            }

        }
        )
}

export const channelUserStatus = (token, channel_id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    let data = {
        channel_id: channel_id,
        status: "offline"
    }

    dispatch({ type: 'CHAT_ALL_REQUEST', payload: {} })
    axios.post(`${process.env.REACT_APP_BASE_URL}channelUserStatus`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'CHAT_ALL_SUCCESS', payload: {
                    }
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'CHAT_ALL_FAILED', payload: {} })
        }
        )
}


export const uploadMediaFiles = (token, data, cancelFileUpload, cb, Auth_err) => (dispatch) => {
    const options = {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'content-type': 'multipart/form-data'

        },
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total)
            dispatch({ type: 'SET_CHAT_DATA', payload: { fileChatPercentage: percent } })
        },
        cancelToken: new CancelToken(
            cancel => (cancelFileUpload.current = cancel)
        )
    };

    dispatch({ type: 'CHAT_ALL_REQUEST', payload: { UploadButtonLoader: true } })
    axios.post(`${process.env.REACT_APP_BASE_URL}uploadFileChat`, data, options)
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'CHAT_ALL_SUCCESS', payload: {
                        UploadButtonLoader: false
                    }
                })
                cb(res.data.details)
            }
            else {
                dispatch({
                    type: 'CHAT_ALL_FAILED', payload: {
                        UploadButtonLoader: false,
                        fileChatPercentage: ''
                    }
                })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {

            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { UploadButtonLoader: false,fileChatPercentage: '' } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Auth_err()
                    }
                }
            }
        }
        )
}

export const validateSession = (token,sessionid, host,user_name,Cb,bool) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization:  `Bearer ${token}`,
    }

    const head2 = {
        Accept: 'application/json',
    }

    let data = {
        generate:bool,
        session_id: sessionid,
        host: host,
        name:user_name ? user_name.first_name:undefined,
        // last_name:user_name ? user_name.last_name:undefined
    }
    dispatch({ type: 'CHAT_ALL_REQUEST', payload: {validateloader:true} })
    axios.post(`${process.env.REACT_APP_BASE_URL}validateSession`, data, { headers: bool ? head2 :head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'CHAT_ALL_FAILED', payload: {validateloader:false} })
                const { host, group_id, room_name, name,token,session_id } = res.data.data
                // if(token){
                //     localStorage.setItem('connect_token',token)
                // }
                // localStorage.setItem('connect_session',session_id)

                Cb(true, host, group_id, room_name, name,session_id,token)
            } else {
                dispatch({ type: 'CHAT_ALL_FAILED', payload: {validateloader:false} })
                Cb(false)
            }
        })
        .catch(err => { 
            dispatch({ type: 'CHAT_ALL_FAILED', payload: {validateloader:false} })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Cb(false)
                    }
                    if (err.response.status === 422) {
                        Cb(false)
                    }
                }
            }
        }
        )
}