import React from 'react'
import "./Index.js"
function SessionEnd({unauthorized}) {
    return (
        <div className='video_ended'>
            {
                unauthorized ? 
                'Invalid Request'
                :
                'Video Session Has Ended!'
            }
            
        </div>
    )
}

export default SessionEnd
