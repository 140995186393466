import React, { useEffect, useState } from 'react'
import Video from "./VideoEngine/Index"
import Chat from "./Chat/Chat"
import Loader from "./Loading"
import SessionEnd from "./SessionEnd"
import { useParams } from 'react-router-dom';
import { validateSession } from "../Redux/Actions/ChatAction"
import { useDispatch } from 'react-redux';
import * as jose from 'jose'
import Modal from "./Modal"
import "./Index.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import chaticon from "./chat.svg"
import chaticon_black from "./chat_blk.svg"

function Index() {
    let api
    const params = useParams()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(false)
    const [show_video, setShowVideo] = useState(false)
    const [session_end, setSession] = useState(false)
    const [session_details, setSessionDetails] = useState({ error: '' })
    const [grp, setGroup] = useState('')
    const [show_modal, setShowModal] = useState(false)
    const [count, setCount] = useState(false)
    const [user_name, setName] = useState({ first_name: '', last_name: '' })

    const { session_id, host, name } = params

    const connect_token = localStorage.getItem('connect_token')
    const connect_session = localStorage.getItem('connect_session')

    useEffect(() => {
        if (name) {
            if (connect_session) {
                if (connect_session === session_id) {
                    dispatch(validateSession(connect_token, session_id, host, name ? { 'first_name': name } : '', Cb, false))
                } else {
                    dispatch(validateSession(connect_token, session_id, host, name ? { 'first_name': name } : '', Cb, true))
                }
            } else {
                dispatch(validateSession(connect_token, session_id, host, name ? { 'first_name': name } : '', Cb, true))
            }
        } else {
            setShowModal(true)
        }

    }, [])


    const SubmitName = (e) => {
        e.preventDefault()
        if (user_name.first_name) {
            if (connect_session) {
                if (connect_session === session_id) {
                    dispatch(validateSession(connect_token, session_id, host, user_name, Cb, false))
                } else {
                    dispatch(validateSession(connect_token, session_id, host, user_name, Cb, true))
                }
            } else {
                dispatch(validateSession(connect_token, session_id, host, user_name, Cb, true))
            }
            return;
        }
        alert('First name is required')
    }


    const Cb = (val, host, group_id, room, user, session_id,token) => {
        if (val) {
            setShowModal(false)
            setGroup(group_id)
            setSessionDetails({ error: '' })
            setLoading(false)
            setName({ first_name: '' })
            GenerateToken(host, room, user, session_id,token)
        } else {
            setShowModal(false)
            setSessionDetails({ error: true })
            setShowVideo(false)
            setLoading(false)
            setName({ first_name: '', last_name: '' })
            localStorage.removeItem("connect_token");
            localStorage.removeItem("connect_session");

        }
    }

    const GenerateToken = async (hostcheck, room, user, session_id,token) => {
        var enc = new TextEncoder();
        let key = enc.encode(process.env.REACT_APP_PRIVATE_KEY)

        let data = {
            "context": {
                "user": {
                    "name": user,
                    "email": ""
                }
            },
            "aud": "testapp",
            "iss": "testapp",
            "sub": "videointrv.skillablers.com",
            "room": "*"
        }

        const jwtTok = await new jose.SignJWT(data)
            .setProtectedHeader({ alg: 'HS256', typ: 'JWT' })
            .setIssuer('testapp')
            .setAudience('testapp')
            .setSubject('videointrv.skillablers.com')
            .sign(key)

        const domain = 'videointrv.skillablers.com';
        const users = [];
        const options = {
            roomName: session_id,
            width: 900,
            height: 500,
            parentNode: document.querySelector('#video'),
            jwt: hostcheck == 1 ? jwtTok : '',
            userInfo: {
                email: '',
                displayName: user
            }
        };
        console.log('optionssssssssss', options)
        let JitsiMeetExternalAPI = window.JitsiMeetExternalAPI;
        api = await new JitsiMeetExternalAPI(domain, options);
        localStorage.setItem('connect_token',token)
        localStorage.setItem('connect_session',session_id)
        api.addEventListener("videoConferenceJoined", function (response) {
            ///////to show chat button in screen
            setShowVideo(true)
		if(hostcheck == 1){
		api.executeCommand('startRecording', {
                mode: 'file',
               
            });
	}
        })
        api.addEventListener("videoConferenceLeft", function (response) {
            if (api.getMyId()) {
                HandleScreen()
            }
        })

api.addEventListener("passwordRequired", function (response) {
		console.log("waiting***")
        })


        api.addEventListener("participantKickedOut", function (response) {
            HandleScreen()
        })

    }

    const HandleShow = () => {
        if (!show) {
            setCount(false)
        }
        setShow((prev) => !prev)
    }

    const HandleScreen = () => {
        ////remove video session screen and chat icon
        setShowVideo(false)
        setSession(true)
        setShow(false)
        // localStorage.removeItem("connect_token");
        // localStorage.removeItem("connect_session");
    }

    const HandleName = (e) => {
        setName({ ...user_name, [e.target.name]: e.target.value })
    }

    //////to show chat notification if chat window is closed
    const HandleCount = (msg, full_name) => {
        setCount(true)
        toast(<div className='notification_div'><span className='notification_header'><img src={chaticon_black} alt="" width='20px' height='20px' style={{ marginRight: '3px' }} />{full_name}</span><span className='notification_msg'>{msg}</span></div>, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            pauseOnFocusLoss: false
        });

    }
    return (
        <div className='wrap-div'>
            {
                // initial loader check
                loading ?
                    <>
                        {
                            // checking to show name input page or loader
                            show_modal ? <Modal HandleNameChange={HandleName} SubmitName={SubmitName} user_name={user_name} /> : <Loader />
                        }

                    </>
                    :
                    <>
                        {
                            /////checking to show video engine or session end component
                            session_end ?
                                <SessionEnd />
                                :
                                <div className={'video_engine_div fullwidth'}>
                                    {session_details.error ? <SessionEnd unauthorized={true} /> : <Video />}
                                </div>
                        }
                        {
                            /////checking to show chat if only video engine loaded
                            show_video &&
                            <>
                                <div className={show ? 'chat-div2 active2' : 'chat-div2'}>
                                    <Chat
                                        id={grp}
                                        HandleShow={HandleShow}
                                        Token={connect_token}
                                        Handle_Authorization={Cb}
                                        HandleCount={HandleCount}
                                        show={show}
                                    />
                                </div>
                                <span className='sidebar' onClick={HandleShow}>
                                    {
                                        count ?
                                            <div className='show_notification'>
                                                <img src={chaticon} alt="Chat Logo" width='25px' height='25px' />
                                                <div className='notification_dot'></div>
                                            </div>
                                            :
                                            <img src={chaticon} alt="Chat Logo" width='25px' height='25px' />
                                    }
                                </span>
                            </>
                        }
                    </>
            }
            <ToastContainer />
        </div>
    )
}

export default Index
