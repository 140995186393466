import React, { useEffect, useState, Fragment, useRef } from 'react'
import "./Chat.css"
import Echo from 'laravel-echo';
import Pusher from "pusher-js";
import { setChatStoreData, chatDetails, createChat, uploadMediaFiles } from "../../Redux/Actions/ChatAction"
import { useDispatch, useSelector } from 'react-redux';
import MediaChat from "./MediaChat"
import Swal from 'sweetalert2'
import OverLay from "./OverLay"
import user_avatar from '../../assests/images/singleuser.jpg'

const options = {
    broadcaster: 'pusher',
    key: 'local',
    //  wsHost: window.location.hostname,
    wsHost: 'apivideointrv.skillablers.com',
    // wsHost: "backend.mymots.com",
    wsPort: 6001,
    wssPort: 6001,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],

};
const echo = new Echo(options);


function Chat({ HandleShow, id, Handle_Authorization, HandleCount, show }) {

    const Token = localStorage.getItem('connect_token')


    const dispatch = useDispatch()
    const [chat_data, setChatData] = useState([])
    const [MessageFull, setMessageFull] = useState('')
    const [recieving_message_user, setRecievingMessageUser] = useState('')
    const [message, setMessage] = useState('')
    const [scroll, setScroll] = useState(true)
    const [show_attachment_div, setAttachmentDiv] = useState(false)
    const [Preview, setPreview] = useState({
        active: false,
        file: null,
        type: null
    })

    const chat_next_page = useSelector(state => state.Chat.chat_next_page ? state.Chat.chat_next_page : '')
    const loader = useSelector(state => state.Chat.chat_details_loader ? state.Chat.chat_details_loader : false)
    const page = useSelector(state => state.Chat.chat_details_page ? state.Chat.chat_details_page : 1)
    const chat_name = useSelector(state => state.Chat.chat_name ? state.Chat.chat_name : '')
    const current_user_id = useSelector(state => state.Chat.current_user_id ? state.Chat.current_user_id : '')


    const cancelFileUpload = useRef(null);

    useEffect(() => {
        dispatch(chatDetails(Token, id, page, Cb_chat_details, Handle_Authorization))
    }, [page])

    useEffect(() => {

        if (MessageFull && current_user_id !== recieving_message_user) {
            if (!show) {
                HandleCount(MessageFull.message, MessageFull?.full_name)
            }
            let arr = [...chat_data]
            let obj = {
                'created_time': new Date().toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' }),
                'is_right': false,
                'message': MessageFull.message ? MessageFull.message : '',
                'type': MessageFull.type ? MessageFull.type : '',
                "full_name": MessageFull.full_name ? MessageFull.full_name : '',
                "file_url": MessageFull.file_url ? MessageFull.file_url : '',
                "video_thumbnail": MessageFull.video_thumbnail ? MessageFull.video_thumbnail : '',
                "video_embed": MessageFull.video_embed ? MessageFull.video_embed : '',
                "file_size": MessageFull.file_size ? MessageFull.file_size : '',
                "file_name": MessageFull.file_name ? MessageFull.file_name : '',
                "extension": MessageFull.extension ? MessageFull.extension : '',
                "invitation_msg": MessageFull.invitation_msg ? MessageFull.invitation_msg : 0,
            }
            arr.push(obj)
            setChatData(arr)
            setMessageFull('')
            setTimeout(() => {
                let myDiv = document.getElementById("msger_chat_id");
                myDiv.scrollTop = myDiv.scrollHeight;
            }, 600)
        }
        else {
            setMessageFull('')
        }

    }, [MessageFull])


    const Cb_chat_details = (val, name) => {
        let arr = val.reverse()
        let concat_arr = [...arr, ...chat_data]
        setChatData(concat_arr)
        Cb2(name)
        if (scroll) {
            setScroll(false)
            setTimeout(() => {
                let myDiv = document.getElementById("msger_chat_id");
                myDiv.scrollTop = myDiv.scrollHeight;
            }, 600)
        }
    }

    const Cb2 = (name) => {
        // setChannelName(name)
        echo.channel(name).listen('ChatEvent', (e) => {
            setRecievingMessageUser(e.user_id)
            setMessageFull(e)
            console.log("log event", e)
        });
    }

    const LoadMore = () => {
        dispatch(setChatStoreData('chat_details_page', page + 1))
    }

    const HandleMessage = (e) => {
        setMessage(e.target.value)
    }

    const SendMessage = (e) => {
        e.preventDefault()
        if (message.trim().length !== 0) {
            let msg = message
            let obj = {
                'created_time': new Date().toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' }),
                'is_right': true,
                'message': msg,
                'type': 'text'
            }
            let arr = [...chat_data]
            arr.push(obj)
            setChatData(arr)
            setMessage('')
            dispatch(createChat(Token, msg, id, 'group', Handle_Authorization))
            setTimeout(() => {
                let myDiv = document.getElementById("msger_chat_id");
                myDiv.scrollTop = myDiv.scrollHeight;
            }, 600)
        }
    }


    let DocumentTypes = ['application/pdf', 'application/epub+zip', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.ms-excel', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']

    const handleFileSelect = (type, file) => {
        if (file && DocumentTypes.includes(file.type)) {
            setPreview({ active: true, file: file, type: type })
        }
        else {
            Swal.fire('File Not Valid', "Selected File Isn't a Valid Document File", 'error')
        }
    }


    const handleClosePreview = () => {
        cancelUpload()
        setPreview({ ...Preview, active: false, type: '', file: '' })
    }

    const cancelUpload = () => {
        if (cancelFileUpload.current)
            cancelFileUpload.current("User has canceled the file upload.");
    };

    const handleUpload = () => {
        dispatch(setChatStoreData('fileChatPercentage', null))
        const formData = new FormData();
        formData.append('file', Preview.file)
        formData.append('type', Preview.type)
        formData.append('group_id', id)

        dispatch(uploadMediaFiles(Token, formData, cancelFileUpload, uploadcb, AuthErr))
    }

    const uploadcb = () => {
        dispatch(setChatStoreData('fileChatPercentage', null))
        setPreview({ ...Preview, active: false, type: null })
        setAttachmentDiv(false)
        if (page === 1) {
            dispatch(chatDetails(Token, id, 1, refresh, Handle_Authorization))
        } else {
            setScroll(true)
            dispatch(setChatStoreData('chat_details_page', 1))
        }

    }

    const AuthErr = () => {
        dispatch(setChatStoreData('fileChatPercentage', null))
        setPreview({ ...Preview, active: false, type: null })
        setAttachmentDiv(false)
        Handle_Authorization()
    }

    const refresh = (val) => {
        let arr = val.reverse()
        setChatData(arr)
        setTimeout(() => {
            let myDiv = document.getElementById("msger_chat_id");
            myDiv.scrollTop = myDiv.scrollHeight;
        }, 600)
    }

    return (
        <Fragment>
            <section className="msger">
                <header className="msger-header">
                    <div className="msger-header-title">
                        {chat_name}
                    </div>
                    <div className='show-closs' onClick={HandleShow}>
                        <i class="fas fa-times fa-lg"></i>
                    </div>
                </header>

                <main className="msger-chat" id='msger_chat_id'>
                    {
                        chat_next_page ?
                            <div style={{ width: '100%', margin: 'auto', display: 'flex', justifyContent: 'center' }}>
                                {
                                    loader ?
                                        <span>Loading...</span>
                                        :
                                        <span style={{ cursor: 'pointer', color: 'blue', }} onClick={LoadMore}>Load More..</span>
                                }
                            </div>
                            :
                            ''
                    }


                    {
                        chat_data.length > 0 ? chat_data.map((val, ky) => {
                            if (val.invitation_msg === 1) {
                                return (
                                    <div key={ky} classNameName='invitation_msg'>
                                        <div>{val.message}</div>
                                    </div>
                                )
                            }
                            else if (val.is_right) {
                                if (val.type === "text") {
                                    return (

                                        <div className="msg right-msg" key={ky}>
                                            {/* <div
                                                className="msg-img"
                                                style={{ backgroundImage: `url(${user_avatar})` }}
                                            ></div> */}

                                            <div className="msg-bubble">
                                                <div className="msg-info">
                                                    <div className="msg-info-name">You</div>
                                                    <div className="msg-info-time">{val.created_time}</div>
                                                </div>

                                                <div className="msg-text">
                                                    {val.message}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <div className="msg right-msg" key={ky}>
                                            {/* <div
                                                className="msg-img"
                                                style={{ backgroundImage: `url(${user_avatar})` }}
                                            ></div> */}

                                            <div className="msg-bubble">
                                                <div className="msg-info">
                                                    <div className="msg-info-name">You</div>
                                                    <div className="msg-info-time">{val.created_time}</div>
                                                </div>

                                                <div className="msg-text">
                                                    <MediaChat val={val} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            }
                            else {
                                if (val.type === "text") {
                                    return (
                                        <div className="msg left-msg" key={ky}>
                                            {/* <div
                                                className="msg-img"
                                                style={{ backgroundImage: `url(${user_avatar})` }}
                                            ></div> */}

                                            <div className="msg-bubble">
                                                <div className="msg-info">
                                                    <div className="msg-info-name">{val.full_name}</div>
                                                    <div className="msg-info-time">{val.created_time}</div>
                                                </div>

                                                <div className="msg-text">
                                                    {val.message}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <div className="msg left-msg" key={ky}>
                                            {/* <div
                                                className="msg-img"
                                                style={{ backgroundImage: `url(${user_avatar})` }}
                                            ></div> */}

                                            <div className="msg-bubble">
                                                <div className="msg-info">
                                                    <div className="msg-info-name">{val.full_name}</div>
                                                    <div className="msg-info-time">{val.created_time}</div>
                                                </div>

                                                <div className="msg-text">
                                                    <MediaChat val={val} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                            }
                        })
                            :
                            <div style={{ width: '100%', margin: 'auto', display: 'flex', justifyContent: 'center' }}>
                                {loader ? "" : <span>No messages</span>}
                            </div>
                    }

                </main>

                <form className="msger-inputarea" onSubmit={SendMessage}>
                    <input
                        type="text"
                        className="msger-input"
                        placeholder="Type here..."
                        value={message}
                        onChange={HandleMessage}
                    />
                    <button
                        type="submit"
                        className={message ? "msger-send-btn":"msger-send-btn disabled-snd"}
                        disabled={!message}
                    >
                        <i class="fas fa-paper-plane fa-lg"></i>
                    </button>
                    <div>

                        <div className='attachment_item'>
                            <label for='doccument-file'>
                                <i class="fas fa-file fa-lg"></i>
                            </label>
                            <input onClick={(e) => e.target.value = ''} onChange={(e) => handleFileSelect('document', e.target.files[0])} style={{ visibility: 'hidden' }} type='file' id='doccument-file' accept="application/msword, application/vnd.ms-excel,application/vnd.ms-powerpoint, application/vnd.ms-powerpoint, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document,.ppt, .pptx, .txt, .xls, .xlsx, .xlsm" ></input>
                        </div>

                    </div>
                </form>
            </section>

            {Preview.active &&
                <OverLay
                    type={Preview.type}
                    file={Preview.file}
                    handleClose={handleClosePreview}
                    handleUpload={handleUpload}
                />
            }
        </Fragment>
    )
}

export default Chat
