export default function ChatReducer(state = {}, action) {
    switch (action.type) {
      case "SET_CHAT_DATA": {
        return { ...state, ...action.payload };
      }
      case "CHAT_ALL_REQUEST": {
        return { ...state, ...action.payload, }
      }
      case "CHAT_ALL_SUCCESS": {
        return { ...state, ...action.payload }
      }
      case "CHAT_ALL_FAILED": {
        return { ...state, ...action.payload }
      }
      
      default: {
        return { ...state }
      }
  
    }
  }
  