import React from 'react'
import "./Modal.css"
import { useSelector } from "react-redux"

function Modal({HandleNameChange,user_name,SubmitName}) {
    
    const validateloader = useSelector(state => state.Chat.validateloader ? state.Chat.validateloader:false)
    return (
        <div className='modal_wraper'>
            <form className='modal_div' onSubmit={SubmitName}>
                <h3 style={{textAlign:'center'}}>What's your name?</h3>
                <div>
                    <input className='modal_input' type="text" placeholder='First Name' name='first_name' value={user_name.first_name} onChange={HandleNameChange} /> 
                    {/* <input className='modal_input' type="text" placeholder='Last Name' name='last_name' value={user_name.last_name} onChange={HandleNameChange} />  */}
                </div>
                {
                    validateloader ? 
                    <button type='submit' className={'button_active'} disabled={true}>Loading...</button>
                    :
                    <button type='submit' className={(user_name.first_name ) ? 'button_active':'button_inactive'} disabled={user_name.first_name ? false :true}>Submit</button>

                }
            </form>

            <span className='side_logo'>Connect</span>
        </div>
    )
}

export default Modal
