import React, { useState } from 'react'
import PdfIcon from '../../assests/images/pdficon.png'
import DocxIcon from '../../assests/images/docxicon.png'
import SheetIcon from '../../assests/images/sheeticon.png'
import blank_sheet from '../../assests/images/blank_sheet.png'
import image_processing from '../../assests/images/image_processing.jpg'
import parse from 'html-react-parser';
import ReactPlayer from 'react-player'


function Index({ val }) {

    const handleDownload = (url) => {

        var link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.click();
        link.remove();
    }

    const [OverLay, setOverLay] = useState({ isOpen: false, data: null, type: null })

    const closeOverlay = () => {
        setOverLay({ isOpen: false, data: null, type: null })
    }

    const openOverlay = (type, data) => {
        setOverLay({ isOpen: true, data: data, type: type })
    }

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column',width:'100% ' }}>
                {
                    val.type === 'image' ?
                        <div style={{ width: '100%', height: '170px', display: 'flex', flexDirection: 'column',alignItems:'center' }} >
                            <img src={val.file_url} alt='' onClick={() => openOverlay('image', val)} width='170px' height='170px' />

                            <div style={{ width: '170px', display: 'flex', justifyContent: 'flex-end' }}>
                                <div style={{ fontSize: '12px', paddingTop: '3px' }} > {val.file_size} </div>

                                <div onClick={() => handleDownload(val.file_url)} style={{ cursor: 'pointer', borderRadius: '3px', verticalAlign: 'middle' }} >
                                    <i class="fas fa-arrow-down"></i>
                                </div>

                            </div>
                        </div>
                        :
                        val.type === 'document' ?
                            <div style={{ minWidth: '170px', display: 'flex', alignItems: 'center', height: '50px', justifyContent: 'space-between' }}>

                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {
                                        val.extension === 'pdf' ?
                                            <div className='document_items'>
                                                <img src={PdfIcon} alt='' width='40px' height='40px' ></img> <span style={{ fontSize: '13px' }}>{val.file_name?.length < 35 ? val.file_name : val.file_name?.slice(0, 35) + '... '}</span>
                                            </div>
                                            :
                                            val.extension === ('xls' || 'sheet' || 'xls' || 'csv' || 'tsv') ?
                                                <div className='document_items'>
                                                    <img src={SheetIcon} alt='' width='40px' height='40px' ></img> <span style={{ fontSize: '13px' }}>{val.file_name?.length < 35 ? val.file_name : val.file_name?.slice(0, 35) + '... '}</span>
                                                </div>
                                                :
                                                val.extension === ('docx' || 'doc' || 'docm' || 'dot') ?
                                                    <div className='document_items'>
                                                        <img src={DocxIcon} alt='' width='40px' height='40px' ></img> <span style={{ fontSize: '13px' }}>{val.file_name?.length < 35 ? val.file_name : val.file_name?.slice(0, 35) + '... '}</span>
                                                    </div>
                                                    :
                                                    <div className='document_items'>
                                                        <img src={blank_sheet} alt='' width='40px' height='40px' ></img> <span style={{ fontSize: '13px' }}>{val.file_name?.length < 35 ? val.file_name : val.file_name?.slice(0, 35) + '... '}</span>
                                                    </div>


                                    }
                                    <span className='ml-2' style={{ fontSize: '10px' }}>{val.file_size} . {val.extension}</span>
                                </div>
                                <div onClick={() => handleDownload(val.file_url)} style={{ cursor: 'pointer', marginLeft: '6px' }}>
                                    <i class="fas fa-arrow-down"></i>
                                </div>
                            </div>
                            :
                            val.type === 'audio' ?
                                <>
                                    <div style={{ minWidth: '170px', display: 'flex', alignItems: 'center', height: '50px', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', wordWrap: 'break-word' }}>
                                            <i class="fas fa-file-audio"></i>
                                            <span style={{ fontSize: '13px', marginLeft: '4px' }}> <span style={{ marginLeft: '4px' }} > {val.file_name.length < 35 ? val.file_name : val.file_name.slice(0, 35) + '... '}  </span> </span>
                                        </div>
                                        <div onClick={() => handleDownload(val.file_url)} style={{ cursor: 'pointer', marginLeft: '6px' }}>
                                            <i class="fas fa-arrow-down"></i>
                                        </div>

                                    </div>
                                    <span className='ml-2' style={{ fontSize: '10px' }}>{val.file_size} . {val.extension}</span>
                                </>
                                :
                                val.type === 'video' ?
                                    <div style={{ width: '200px', height: '113px', position: 'relative' }}>
                                        {
                                            val.video_processed === 1 ?
                                                <img
                                                    src={val.video_thumbnail ? val.video_thumbnail[3]?.link_with_play_button : ''}
                                                    alt=''
                                                    style={{ borderRadius: '10px' }}
                                                    width='100%' height='100%'
                                                    onClick={() => openOverlay('video', val)}
                                                />

                                                :
                                                <img alt='processing' src={image_processing} width='100%' height='100%' />

                                        }
                                        {
                                            val.video_processed === 1 ?
                                                <div className='video_size_div pl-2'>
                                                    <span>
                                                        {/* <i class="fas fa-arrow-down"></i> */}
                                                        {val.file_size}
                                                    </span>
                                                </div>
                                                :
                                                <div className='video_processing_div'>
                                                    <span>Processing</span>
                                                </div>
                                        }
                                    </div>
                                    :
                                    ''
                }
            </div>

            {OverLay.isOpen &&

                <div 
                onClick={closeOverlay}
                style={{
                    position: "fixed",
                    backgroundColor: 'rgba(0,0,0,0.9)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: '0px',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    zIndex: '98',
                    cursor:'pointer'
                }} >
                    <div className='my-auto player-wrapper' style={{ margin: '10px', display: 'flex' }} >
                        <div className='player-wrapper'>
                            {OverLay.type === 'video' ?
                                <>
                                    {
                                        val.camera_tag_url ?
                                            <ReactPlayer
                                                url={OverLay.data?.file_url}
                                                controls={true}
                                            />
                                            :
                                            parse(OverLay.data?.video_embed)
                                    }

                                </>
                                : <img style={{ maxWidth: '600px', maxHeight: '300px' }} alt='failed to load img' src={OverLay.data?.file_url} ></img>
                            }
                        </div>

                        {/* <div style={{ color: 'white', marginLeft: '30px', backgroundColor: 'red' }} >
                            <div style={{ textAlign: 'right', width: '100%', fontSize: '30pt', marginBottom: '80px',backgroundColor:'yellow' }} >
                                <i onClick={closeOverlay} style={{ cursor: 'pointer' }} className='pe-7s-close' ></i>
                            </div>
                        </div> */}
                    </div>
                </div>

            }

        </>
    )
}

export default Index
